<template>
  <div>
    <!-- 通知公告 -->
    <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
      <el-form-item>
        <el-input v-model="queryParams.title" clearable placeholder="请输入标题" @change="resetPageAndRefresh"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="info" icon="el-icon-search" @click="resetPageAndRefresh">搜索</el-button>
      </el-form-item>
    </el-form>
    
    <div class="content-wrap">
      <div class="btn-wrap cfx">
        <el-button type="success" size="small" icon="el-icon-plus" @click="handleForm(false)" v-if="OA.includes('jcxx:tzgg:add')">添加</el-button>
      </div>
      <div v-if="noticeTotal==0" class="totalNull"><img src="../../assets/images/bg.png" alt=""></div>
      <!--列表-->
      <paged-table
        v-if="noticeTotal>0"
        :data="noticeList"
        :total="noticeTotal"
        :loading="tLoading"
        :isShowSelection="false"
        defaultSortProp="id"
        v-model="queryParams"
        @refresh="refresh"
      > 
        <el-table-column prop="title" show-overflow-tooltip label="标题"></el-table-column>
        <el-table-column prop="title" show-overflow-tooltip label="所在学校"></el-table-column>
        <el-table-column prop="title" show-overflow-tooltip label="发布者身份"></el-table-column>
        <el-table-column prop="extend.addUserName" show-overflow-tooltip label="发布者"></el-table-column>
        <el-table-column show-overflow-tooltip label="发布类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1">通知</span>
            <span v-if="scope.row.type === 2">公告</span>
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip label="发布状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 0">草稿</el-tag>
            <el-tag type="success" v-if="scope.row.status === 1">已发布</el-tag>
            <el-tag type="danger" v-if="scope.row.status === 2">关闭</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" show-overflow-tooltip label="备注"></el-table-column>
        <el-table-column align="right" label="操作" width="120" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleDetails(scope.row)" icon="el-icon-search" title="详情"></el-button>
            <el-button size="mini" type="primary" @click="handleForm(true, scope.row)" v-if="scope.row.status == 0 && OA.includes('jcxx:tzgg:editor')" icon="el-icon-edit" title="编辑"></el-button>
            <el-button size="mini" type="danger" @click="handleDelete({id: scope.row.id})" v-if="scope.row.status == 0 && OA.includes('jcxx:tzgg:del')" icon="el-icon-delete" title="删除"></el-button>
          </template>
        </el-table-column>
      </paged-table>
    </div>

    <!-- 编辑界面 -->
    <el-dialog :title="isEditor ? '编辑' : '添加'" :visible.sync="aVisible" width="80%" :close-on-click-modal="false">
      <el-form label-width="100px" ref="formFileds" :model="form" :rules="formRules" size="small">
        <el-form-item label="标题：" prop="title" class="inlineForm w450">
          <el-input v-model="form.title" clearable></el-input>
        </el-form-item>
        <el-form-item label="公告类型：" class="inlineForm w450">
          <el-select
            v-model="form.type"
            placeholder="公告类型"
            @change="resetPageAndRefresh"
          >
            <el-option label="通知" :value="1"></el-option>
            <el-option label="公告" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="公告状态：" class="inlineForm w450">
          <el-select
            v-model="form.status"
            placeholder="公告状态"
          >
            <el-option label="草稿" :value="0"></el-option>
            <el-option label="发布" :value="1"></el-option>
            <el-option label="关闭" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark" class="inlineForm w450">
          <el-input v-model="form.remark" clearable></el-input>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content">
          <tinymce v-model="form.content" v-if="aVisible"></tinymce>
        </el-form-item>
        
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="aVisible = false">取消</el-button>
        <el-button size="small" type="primary" :loading="bLoading" @click="isEditor ? submitForm(editorApi) : submitForm(insertApi)">保存</el-button>
      </div>
    </el-dialog>

    <!-- 详情界面 -->
    <el-dialog title="详情" :visible.sync="bVisible" width="80%" :close-on-click-modal="false">
      <el-form label-width="100px" ref="detailsFileds" :model="details" size="small">
        <el-form-item label="公告标题：" prop="title">
          <span v-text="details.title"></span>
        </el-form-item>
        <el-form-item label="公告内容：" prop="content">
          <span v-html="details.content"></span>
        </el-form-item>
        <el-form-item label="公告类型："  prop="type">
          <span v-if="details.type === 1">通知</span>
          <span v-if="details.type === 2">公告</span>
        </el-form-item>
        <el-form-item label="公告状态："  prop="status">
          <span v-if="details.status === 0">草稿</span>
          <span v-if="details.status === 1">发布</span>
          <span v-if="details.status === 2">关闭</span>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <span v-text="details.remark"></span>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import pageMixin from "@/mixins/pageMixin";
import { log, mergeObject } from '@/common/utils'
import Tinymce from "@/tinymce";
export default {
  name:'noticeManagement',
  components: {
    PagedTable,
    Tinymce
  },
  mixins: [pageMixin],
  data() {
    return {
      queryParams: {
        title: null,
        appId:1
      },
      form:{
        id: '',
        title:'',
        content:'',
        type: '',
        status: '',
        appId: 1,
        remark:'',
      },
      details:{
        title:'',
        content:'',
        type: '',
        status: '',
        remark:'',
      },
      formRules:{
        name: [{ required: true, message: '请输入通知公告名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择通知公告类型', trigger: 'change' }],
        url: [{ required: true, message: '请输入链接地址', trigger: 'blur' }],
      },

      pageApi:'commonNoticeList',
      insertApi:'commonNoticeAdd',
      editorApi:'commonNoticeEdit',
      deleteApi:'commonNoticeDelete',
      loadApi:'commonNoticeLoad',
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("basicNotice", ["noticeList", "noticeTotal"]),
  },
  methods: {
    ...mapActions("basicNotice",["commonNoticeList", "commonNoticeAdd", "commonNoticeEdit", "commonNoticeDelete", "commonNoticeLoad"]),
  },
  async mounted() {
    if(this.getPageParams(this.$options.name))
      mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
  }
};
</script>